<template>
  <div
    class="user-profile-sidebar"
    :class="{ show: shallShowActiveChatContactSidebar }"
  >
    <!-- Header -->
    <header v-if="contact" class="user-profile-header">
      <span class="close-icon">
        <feather-icon
          icon="XIcon"
          @click="$emit('update:shall-show-active-chat-contact-sidebar', false)"
        />
      </span>

      <div class="header-profile-sidebar">
        <div class="avatar box-shadow-1 avatar-xl avatar-border">
          <b-avatar size="70" :src="contact.avatar" variant="transparent" />
          <span
            class="avatar-status-xl"
            :class="`avatar-status-${contact.status}`"
          />
        </div>
        <h4 class="chat-user-name">
          {{ contact.fullName }}
        </h4>
        <span class="user-post text-capitalize">{{ contact.role }}</span>
      </div>
    </header>

    <!-- User Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="user-profile-sidebar-area scroll-area"
    >
      <!-- About -->
      <h6 class="section-label mb-1">
        About
      </h6>
      <p v-if="allDetails">{{ allDetails.Bio }}</p>

      <!-- Personal Info -->
      <div class="personal-info" v-if="allDetails">
        <h6 class="section-label mb-1 mt-3">
          Personal Information
        </h6>
        <ul class="list-unstyled">
          <li class="mb-1">
            <feather-icon icon="MailIcon" size="16" class="mr-75" />
            <span class="align-middle">{{ allDetails.email }}</span>
          </li>
          <li class="mb-1">
            <feather-icon icon="PhoneCallIcon" size="16" class="mr-75" />
            <span class="align-middle">{{ allDetails["ContactNo."] }}</span>
          </li>
          <li>
            <feather-icon icon="ClockIcon" size="16" class="mr-75" />
            <span class="align-middle">24 * 7 available</span>
          </li>
        </ul>
      </div>

      <!-- More Options -->
      <!-- <div class="more-options">
        <h6 class="section-label mb-1 mt-3">
          Options
        </h6>
        <ul class="list-unstyled">
          <li class="cursor-pointer mb-1">
            <feather-icon icon="TagIcon" size="16" class="mr-75" />
            <span class="align-middle">Add Tag</span>
          </li>
          <li class="cursor-pointer mb-1">
            <feather-icon icon="StarIcon" size="16" class="mr-75" />
            <span class="align-middle">Important Contact</span>
          </li>
          <li class="cursor-pointer mb-1">
            <feather-icon icon="ImageIcon" size="16" class="mr-75" />
            <span class="align-middle">Shared Media</span>
          </li>
          <li class="cursor-pointer mb-1">
            <feather-icon icon="TrashIcon" size="16" class="mr-75" />
            <span class="align-middle">Delete Contact</span>
          </li>
          <li class="cursor-pointer">
            <feather-icon icon="SlashIcon" size="16" class="mr-75" />
            <span class="align-middle">Block Contact</span>
          </li>
        </ul>
      </div> -->
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BAvatar } from "bootstrap-vue";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export default {
  components: {
    // BSV
    BAvatar,

    // 3rd Party
    VuePerfectScrollbar,
  },
  data(){
    return{
      firebasedata: null
    }
  },
  props: {
    shallShowActiveChatContactSidebar: {
      type: Boolean,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
  },
  watch :{
    contact : function () {
      if(this.contact.role == 'student'){
      firebase
          .firestore()
          .collection("StudentID")
          .doc(this.contact.id)
          .get()
          .then((doc) => {
            this.firebasedata = doc.data();
                  this.allDetails

          });
      }
      else if(this.contact.role == 'support'){
        this.allDetails
      }
    }
  },
  created(){
    firebase
          .firestore()
          .collection("StudentID")
          .doc(this.contact.id)
          .get()
          .then((doc) => {
            this.firebasedata = doc.data();
            this.allDetails
          });
  },
  computed: {
    allDetails() {
      var obj = {
          Bio: "",
          email: "",
          "ContactNo.": "",
        };
      // console.log('checking contact details')
      if (this.contact.role == 'student') {
        obj = this.firebasedata
      }else if(this.contact.role == 'support'){
        obj = {
          Bio: "I'm your 24/7 flairboat support. You can connect with me anytime. I'll be there to solve any of your queries.",
          email: "support@flairboat.com",
          "ContactNo.": "",
        };
      } else {
        obj = {
          Bio: "",
          email: "",
          "ContactNo.": "",
        };
      }
      return obj;
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    return {
      perfectScrollbarSettings,
    };
  },
};
</script>

<style></style>
