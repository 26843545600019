<template>
  <div class="chats">
    <div v-for="(msgGrp, index) in formattedChatData.formattedChatLog" :key="msgGrp.senderId + String(index)"
      class="chat" :class="{ 'chat-left': msgGrp.senderId === formattedChatData.contact.id }">
      <!--  <template v-if="message[index-1]">
                <vs-divider v-if="!isSameDay(msg.msg.time, message[index-1].msg.time)" class="msg-time">
                    <span>{{ toDate(msg.msg.time) }}</span>
                </vs-divider>
                <div class="spacer mt-8" v-if="!hasSentPreviousMsg(message[index-1].msg.isSent, msg.msg.isSent)"></div>
            </template> 
      <div
        v-for="(msgData, index) in msgGrp.messages"
        :key="msgData.time + msgData.msg"
        class="chat-date-divider"
      >
      <template v-if="msgGrp.messages[index - 1]">
        <div
          style="width: 100%; height: 20px; border-bottom: 1px solid black; text-align: center"
          v-if="!isSameDay(msgData.time, msgGrp.messages[index - 1].time)"
          class="msg-time"
        >
          <span
            style="font-size: 24px; background-color: #F3F5F6; padding: 0 10px;"
          >
            {{ toDate(msgData.time) }}
          </span>
        </div>
      </template>
      </div> -->
      <div class="chat-avatar">
        <b-avatar square size="36" class="avatar-border-2 box-shadow-1 text-dark" 
        :text="msgGrp.senderId === formattedChatData.contact.id
        ? avatarText(formattedChatData.contact.fullName)
        : avatarText(profileUserName)" 
        variant="transparent" 
        style="border-radius: 10px !important;" 
        :src="msgGrp.senderId === formattedChatData.contact.id
      ? (formattedChatData.contact.avatar == '' ? undefined : formattedChatData.contact.avatar)
      : (profileUserAvatar == '' ? undefined : profileUserAvatar)
  " ></b-avatar>
      </div>
      <div class="chat-body">
        <div v-for="(msgData, ind) in msgGrp.messages" :key="msgData.time + msgData.msg">
          <template v-if="typeof msgGrp.messages[ind - 1] != 'undefined'">
            <div class="chat-time-divider" v-if="!isSameDay(msgData.time, msgGrp.messages[ind - 1].time)">
              <span class="mb-2 font-bold ">{{ toDate(msgData.time) }}</span>
            </div>
          </template>
          <template v-if="ind == 0 && index != 0">
            <div class="chat-time-divider"
              v-if="!isSameDay(msgData.time, formattedChatData.formattedChatLog[index - 1].messages[formattedChatData.formattedChatLog[index - 1].messages.length - 1].time)">
              <span class="mb-2 font-bold ">{{ toDate(msgData.time) }}</span>
            </div>
          </template>
          <!-- <template v-if="ind == 0 ? (formattedChatData.formattedChatLog[index - 1].messages[formattedChatData.formattedChatLog[index - 1].messages.length - 1])"></template> -->

          <div class="chat-content">
            <p v-if="msgData.files === '' || msgData.files == null">
              {{ msgData.msg }}
            </p>
            <b-img v-else :src="msgData.files" blank-color="#ccc" alt="attachment" width="500"
              style="border-radius: 4px;     max-width: 100%;" />
            <div class="text-right">
              <small class="chat-time">{{
                  formatChatDateTime(msgData.time, {
                    hour: "numeric",
                    minute: "numeric",
                  })
              }}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/composition-api";
import { BAvatar, BImg } from "bootstrap-vue";
import { formatChatDateTime } from "@core/utils/filter";
import { sortCompare } from "@/@fake-db/utils";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    BAvatar,
    BImg,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    profileUserAvatar: {
      type: String,
      required: true,
    },
    profileUserName: {
      type: String,
      required: true,
    },
  },
  methods: {
    isSameDay(time_to, time_from) {
      const date_time_to = new Date(Date.parse(time_to));
      const date_time_from = new Date(Date.parse(time_from));
      // console.log(
      //   date_time_to.getFullYear() === date_time_from.getFullYear() &&
      //     date_time_to.getMonth() === date_time_from.getMonth() &&
      //     date_time_to.getDate() === date_time_from.getDate()
      // );
      return (
        date_time_to.getFullYear() === date_time_from.getFullYear() &&
        date_time_to.getMonth() === date_time_from.getMonth() &&
        date_time_to.getDate() === date_time_from.getDate()
      );
    },

    toDate(time) {
      //  //console.log(time)
      const locale = "en-us";
      const date_obj = new Date(Date.parse(time));
      const monthName = date_obj.toLocaleString(locale, {
        month: "short",
      });
      return date_obj.getDate() + " " + monthName;
    },
  },
  created() {
    // console.log(this.formattedChatData);
    // if(this.formattedChatData){
    //   this.formattedChatData.formattedChatLog.forEach((msgGroup) => {
    //     msgGroup.messages.sort((a,b) => {
    //       return new Date(a.time) - new Date(b.time)
    //     })
    //   })
    //   console.log(this.formattedChatData)
    // }
  },
  setup(props) {
    const formattedChatData = computed(() => {
      const contact = {
        id: props.chatData.contact.id,
        avatar: props.chatData.contact.avatar,
      };

      let chatLog = [];
      if (props.chatData.chat) {
        chatLog = props.chatData.chat
          .sort((a, b) => {
            return new Date(b.time) - new Date(a.time);
          })
          .reverse();
      }

      const formattedChatLog = [];
      let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : undefined;
      let msgGroup = {
        senderId: chatMessageSenderId,
        messages: [],
      };

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.senderId) {
          msgGroup.messages.push({
            msg: msg.message,
            time: msg.time,
            files: msg.files,
          });
        } else {
          chatMessageSenderId = msg.senderId;
          formattedChatLog.push(msgGroup);
          msgGroup = {
            senderId: msg.senderId,
            messages: [
              {
                msg: msg.message,
                time: msg.time,
                files: msg.files,
              },
            ],
          };
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup);
      });

      return {
        formattedChatLog,
        contact,
        profileUserAvatar: props.profileUserAvatar,
      };
    });
    return {
      formattedChatData,
      formatChatDateTime,
      avatarText
    };
  },
};
</script>

<style>
</style>
